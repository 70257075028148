import { useCallback } from 'react'
import { useMutation } from 'hooks'


type ApiData = SearchModal.SearchData[]

const layerTags = {
  news: 'NEWS',
  votings: 'VOTING',
  affiche: 'POSTER',
  ad: 'ADVERTISING',
  projects: 'CITY_PROJECTS',
}

const modifyResult = (data) => (
  data.map((item) => ({
    ...item,
    layerTag: layerTags[item.id],
  }))
)

const getSearchValue = (search) => search.replace(/\/|\s/g, '+')

const useApiSearch = () => {
  const [ submit ] = useMutation<ApiData>({
    url: '/api/search/',
  })

  const searchApi = useCallback((search: string) => {
    const searchValue = getSearchValue(search)

    return submit({
      url: `/api/search/${searchValue}?page=1&per_page=2`,
      method: 'get',
    })
      .then(({ data }) => modifyResult(data))
  }, [ submit ])

  const loadMoreApi = useCallback(({ search, layerId }: { search: string, layerId?: number }) => {
    const searchValue = getSearchValue(search)

    return submit({
      url: `/api/search/${searchValue}?id=${layerId}`,
      method: 'get',
    })
      .then(({ data }) => modifyResult(data)[0])
  }, [ submit ])

  return {
    searchApi,
    loadMoreApi,
  }
}


export default useApiSearch
