import config from 'config'
import links, { routeData } from 'links'
import { useCallback, useMemo } from 'react'
import { usePathname, useSearchParams } from 'router'
import { getAdminModuleTitle } from 'models/settings'

import { useVueRoutes } from 'models/admin'
import { useAccesses, useUser } from 'models/user'
import { useVisitedNotifications } from 'models/notifications'

import { IconName } from 'components/dataDisplay'
import { MenuLinkItem } from 'components/admin'


const getModuleRole = (accesses: UserModel.AccessesApiData['accesses'], module: UserModel.ModuleTag) => {
  return accesses?.find?.((access) => access.module.tag === module)?.role
}

const hasAccess = ({ to, tag, accessRoles, accesses }: {
  to: string,
  tag?: UserModel.ModuleTag,
  accessRoles: string[],
  accesses: UserModel.AccessesApiData[ 'accesses' ],
}) => {
  if (to && typeof to === 'object') {
    console.error('Wrong link provided', to)
  }

  const accessRole = getModuleRole(accesses, tag || routeData[to]?.tag)

  return !accessRoles || accessRoles.includes(accessRole)
}

type AdminLayersNavigationMenuItem = MenuLinkItem<{
  to?: string
  toTab?: string
  href?: string
  subNav?: SubNav[]
}>

type SubNav = MenuLinkItem<{ to?: string, toTab?: string, href?: string, icon: IconName }>

export const useNavigationItems = (props: { counts?: Record<string, number> } = {}) => {
  const { counts = {} } = props
  const { accesses, isAccessesFetching } = useAccesses()
  const { user, isUserFetching } = useUser()

  const navigationItems = useMemo<AdminLayersNavigationMenuItem[]>(() => {
    const items = [
      {
        title: 'АРМ Ситименеджер',
        icon: 'business/statistics_16',
        subNav: [
          {
            to: links.admin.arm.root,
            icon: 'business/dashboard_16',
            accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
          },
          {
            to: links.admin.constructions.root,
            icon: 'file/insert_16',
            accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
          },
          {
            to: links.admin.requests.root,
            icon: 'business/progress_16',
            accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
          },
        ],
      },
      {
        to: links.admin.asudd.root,
        icon: 'transport/traffic-lights_16',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
      {
        title: 'Активный житель',
        icon: 'work/active-citizen_16',
        count: counts.appeals,
        subNav: [
          {
            to: links.admin.appeals.root,
            icon: 'government/new_16',
            count: counts.appeals,
            accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
          },
          {
            toTab: links.manager.votes.root,
            icon: 'communication/message_16',
            accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
          },
        ],
      },
      {
        icon: 'text/align-left_16',
        count: counts.news,
        toTab: links.manager.news.root,
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
      {
        toTab: links.manager.afisha.root,
        icon: 'time/calendar_16',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
      {
        title: 'Службы города',
        icon: 'cadastral/mncpl-control_16',
        subNav: [
          {
            to: links.admin.institutions.root,
            icon: 'cadastral/mncpl-control_16',
            accessRoles: [ 'ADMIN' ],
          },
          {
            to: links.admin.medicine,
            icon: 'government/medicine_16',
            accessRoles: [ 'ADMIN' ],
          },
          {
            to: links.admin.police.root,
            icon: 'government/police_16',
            accessRoles: [ 'ADMIN' ],
          },
          {
            to: links.admin.accessibility.root,
            icon: 'work/invalid_16',
            accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
          },
        ],
      },
      {
        title: 'Городская инфраструктура',
        icon: 'main/avatar_16',
        count: (counts.tko || 0) + (counts.cleaning || 0) + (counts.excavation || 0),
        subNav: [
          {
            to: links.admin.gisZkh,
            icon: 'industry/cold-water_16',
            accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
          },
          {
            to: links.admin.serviceArea,
            icon: 'map/layers_16',
            accessRoles: [ 'ADMIN' ],
          },
          {
            to: links.admin.controlTKO.root,
            icon: 'ecology/tko_16',
            count: counts.tko,
            accessRoles: [ 'ADMIN' ],
          },
          {
            to: links.admin.cleaning.root,
            icon: 'government/city-clean_16',
            count: counts.cleaning,
            accessRoles: [ 'ADMIN' ],
          },
          {
            to: links.admin.excavation.root,
            icon: 'government/construction_16',
            count: counts.excavation,
            accessRoles: [ 'ADMIN', 'MODERATOR' ],
          },
          {
            to: links.admin.communications.root,
            icon: 'industry/gas-supply_16',
            accessRoles: [ 'ADMIN', 'MODERATOR' ],
          },
          {
            to: links.admin.lighting.root,
            icon: 'other/etc_16',
            accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
          },
          {
            to: links.admin.videoControl.root,
            icon: 'IT/video-control_16',
            accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
          },
          {
            to: links.admin.yards.root,
            icon: 'places/park_16',
            accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
          },
          {
            to: links.admin.warningPoints.root,
            icon: 'main/avatar_16',
            accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
          },
          {
            to: links.admin.mobileStations.root,
            icon: 'communication/telephone_16',
            accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
          },
          {
            to: links.admin.kumi.root,
            icon: 'cadastral/blueprint_16',
            accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
          },
          {
            to: links.admin.heating.root,
            icon: 'industry/heat_16',
            accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
          },
          {
            to: links.admin.alerts.root,
            icon: 'communication/telephone_16',
            accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
          },
          {
            to: links.admin.transport.root,
            icon: 'transport/bus_16',
            accessRoles: [ 'ADMIN' ],
          },
          {
            to: links.admin.serviceTransport.root,
            icon: 'main/help_16',
            accessRoles: [ 'ADMIN', 'MODERATOR', 'OPERATOR' ],
          },
        ],
      },
      {
        to: links.admin.elections.root,
        icon: 'government/government_16',
        accessRoles: [ 'ADMIN' ],
      },
      {
        to: links.admin.culture.root,
        icon: 'government/culture_16',
        count: counts.culture,
        accessRoles: [ 'ADMIN' ],
        tag: 'CULTURE_OBJECTS',
      },
      {
        title: 'Умные устройства',
        icon: 'industry/dash_16',
        count: counts.sensors,
        subNav: [
          {
            to: links.admin.scud,
            icon: 'action/lock_16',
            accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
          },
          {
            to: links.admin.counters.root,
            icon: 'industry/dash_16',
            count: counts.sensors,
            accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
          },
          {
            to: links.admin.electric.root,
            icon: 'communication/telephone_16',
            accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
          },
        ],
      },
      (
        config.cityName === 'Волгодонск' && (
          {
            toTab: 'https://tourism.vd.rusatom.dev/admin',
            title: 'Городские экраны',
            icon: 'work/team-people_16',
            tag: 'CITY_SCRNS',
          }
        )
      ),
      {
        title: 'Проекты',
        icon: 'work/active-citizen_16',
        count: (counts.immortals || 0) + (counts['cityProjects-gv'] || 0) + (counts['cityProjects-ib'] || 0) + (counts['cityProjects-sz'] || 0),
        subNav: [
          {
            to: links.admin.immortals.root,
            icon: 'work/team-people_16',
            accessRoles: [ 'ADMIN' ],
            tag: 'IMMORTALS',
            count: counts.immortals,
          },
          {
            toTab: links.manager.projectGv.root,
            icon: 'work/active-citizen_16',
            count: counts['cityProjects-gv'],
            tag: 'PROJECT_GV',
            accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
          },
          {
            toTab: links.manager.projectIb.root,
            icon: 'work/active-citizen_16',
            tag: 'PROJECT_IB',
            count: counts['cityProjects-ib'],
            accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
          },
          {
            toTab: links.manager.projectSz.root,
            icon: 'work/active-citizen_16',
            tag: 'PROJECT_SZ',
            count: counts['cityProjects-sz'],
            accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
          },
          {
            toTab: links.manager.projectBld.root,
            icon: 'work/active-citizen_16',
            tag: 'PROJECT_BLD',
            // count: counts['cityProjects-bld'],
            accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
          },
        ],
      },
      {
        title: 'Бизнес',
        icon: 'business/business-case_16',
        subNav: [
          {
            to: links.admin.rent.root,
            icon: 'retail/payment-hcs_16',
            accessRoles: [ 'ADMIN', 'MODERATOR' ],
          },
          {
            to: links.admin.nto.root,
            icon: 'places/park_16',
            accessRoles: [ 'ADMIN', 'MODERATOR' ],
          },
        ],
      },
      {
        title: 'Платные услуги',
        icon: 'retail/payment-wallet_16',
        subNav: [
          {
            to: links.admin.sport.root,
            icon: 'government/sport_16',
            accessRoles: [ 'ADMIN', 'MODERATOR', 'OPERATOR' ],
          },
          {
            to: links.admin.edu.root,
            icon: 'other/hot-meal_16',
            accessRoles: [ 'ADMIN', 'MODERATOR', 'OPERATOR' ],
          },
          {
            toTab: links.manager.advertising.root,
            icon: 'work/active-citizen_16',
            tag: 'ADVERTISING',
            accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
          },
        ],
      },
      {
        title: 'Безопасность',
        icon: 'government/security_16',
        subNav: [
          {
            to: links.admin.hydrants.root,
            icon: 'industry/hydrant_16',
            accessRoles: [ 'ADMIN', 'MODERATOR' ],
          },
          {
            to: links.admin.roadEvents.root,
            icon: 'transport/bus_16',
            accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
          },
          {
            to: links.admin.covid.root,
            icon: 'government/medicine_16',
            accessRoles: [ 'ADMIN' ],
          },
        ],
      },
      // Should be at the bottom of the list
      {
        to: links.admin.users.root,
        title: 'Пользователи',
        icon: 'main/avatar_16',
        accessRoles: [ 'ADMIN', 'OPERATOR' ],
      },
      {
        to: links.admin.settings.root,
        icon: 'main/settings_16',
        accessRoles: [ 'ADMIN', 'MODERATOR' ],
      },
    ]
      .filter(Boolean)
      .reduce((result, item) => {
        if (item.subNav) {
          const filteredSubNav = item.subNav
            .filter(({ to, toTab, tag, accessRoles }) => hasAccess({ to: to || toTab, tag, accessRoles, accesses }))
            .map((item) => ({
              ...item,
              title: item.title || routeData[item.to]?.title,
              tag: routeData[item.to]?.tag || routeData[item.toTab]?.tag,
            }))

          if (filteredSubNav.length) {
            result.push({
              ...item,
              subNav: filteredSubNav,
            })
          }
        }
        else {
          const isUsersItem = item.to === links.admin.users.root
          const isLegacyAdmin = config.isLegacyAuth && accesses.some(({ admin }) => admin)

          const isItemVisible = hasAccess({ to: item.to || item.toTab, accessRoles: item.accessRoles, accesses })

          if (isItemVisible || (isLegacyAdmin && isUsersItem)) {
            result.push({
              ...item,
              tag: routeData[item.to || item.toTab]?.tag,
            })
          }
        }

        return result
      }, [])

    const itemsWithTitles = items.map((item) => {
      const { subNav, ...rest } = item

      const isExternalLink = (item.to || item.toTab)?.includes('http')

      if (subNav) {
        return {
          ...rest,
          subNav: subNav.map((subNavItem) => ({
            ...subNavItem,
            title: getAdminModuleTitle(accesses, subNavItem.tag),
          })),
        }
      } else {
        return {
          ...rest,
          title: item.title || getAdminModuleTitle(accesses, item.tag),
        }
      }
    })

    return itemsWithTitles
  }, [ accesses, counts ])

  const propsByPathname = (propName) => navigationItems.reduce((result, navItem) => {
    if (navItem.to) {
      result[navItem.to] = navItem[propName]
    }
    if (navItem.subNav) {
      navItem.subNav.forEach((item) => {
        const { to } = item
        result[to] = item[propName]
      })
    }

    return result
  }, {})

  const titlesByPathname = propsByPathname('title')
  const tagsByPathname = propsByPathname('tag')

  return { navigationItems, titlesByPathname, tagsByPathname }
}


const useNavigation = () => {
  useVueRoutes()

  const { counts } = useVisitedNotifications()
  const { navigationItems, titlesByPathname, tagsByPathname } = useNavigationItems({ counts })

  const pathname = usePathname()
  const { from } = useSearchParams()

  const tagKey = Object.keys(tagsByPathname).reverse().find((path) => pathname.includes(path))

  const matchLink = useCallback((link) => {
    const linkAdminPath = link.replace(/^\/admin/, '')
    const adminPath = (from || pathname).replace(/^\/admin/, '')

    if (!linkAdminPath) return !adminPath

    return adminPath.startsWith(linkAdminPath)
  }, [ pathname, from ])

  return {
    matchLink,
    navigationItems,
    title: titlesByPathname[pathname],
    tag: tagsByPathname[tagKey],
  }
}


export default useNavigation
