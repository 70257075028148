import React, { useEffect, useMemo, useState } from 'react'
import { Menu } from '@headlessui/react'

import { useNotifications, useNotificationsUpdate, useVisitedNotifications } from 'models/notifications'

import { Text } from 'components/dataDisplay'
import { Href } from 'components/navigation'
import { ActionButton } from 'components/inputs'

import DropdownAction from '../DropdownAction/DropdownAction'
import NotificationSettingsModal, { openNotificationSettingsModal } from '../components/NotificationSettingsModal/NotificationSettingsModal'


type NotificationsProps = {
  className?: string
}

const Notifications: React.FC<NotificationsProps> = (props) => {
  const { className } = props

  const [ isHidden, setHidden ] = useState(false)
  const [ selectedChannel, setSelectedChannel ] = useState(null)
  const {
    notifications,
    channelsList,
    submitRead,
    fetch,
  } = useNotifications({ channelId: selectedChannel?.id })

  useEffect(() => {
    if (isHidden) {
      setHidden(false)
    }
  }, [ isHidden ])

  useNotificationsUpdate({ fetch })

  const { counts, visitedLinks, setVisited } = useVisitedNotifications()

  const count = counts?.all || 0

  const listItems = useMemo(() => {
    return notifications
      .filter((notification) => !selectedChannel || selectedChannel?.id === notification.channelId)
      .map(({ title, text, date, to, submitRead, readed }, index) => {
        const isVisited = readed || visitedLinks.includes(to)

        const linkProps: { href?: string, to?: string } = {}

        if (/^\/manager/.test(to)) {
          linkProps.href = to
        }
        else {
          linkProps.to = to
        }

        return {
          title,
          text,
          date,
          ...linkProps,
          disabled: isVisited,
          onClick: () => {
            if (!isVisited) {
              setVisited(true)
            }

            submitRead()
          },
        }
      })
  }, [ visitedLinks, notifications, selectedChannel, setVisited ])

  return (
    <>
      {
        !isHidden && (
          <DropdownAction
            className={className}
            title="Уведомления"
            icon="main/notifications_20"
            count={count}
            listItems={listItems}
            channelButtonTitle={selectedChannel?.title || 'Все'}
            rightNode={(
              <ActionButton
                title="Настройка"
                color="rocky"
                onClick={() => {
                  setHidden(true)
                  openNotificationSettingsModal()
                }}
              />
            )}
            rightTitleNode={(
              <Menu.Item
                className="pointer"
                as={Href}
                onClick={submitRead}
              >
                <Text
                  className="opacity-72 overflow-ellipsis webkit-box"
                  message="Пометить всё, как прочитанное"
                  size="s13-r"
                  color="titanic"
                />
              </Menu.Item>
            )}
            menuItems={[
              {
                title: 'Все',
                count: counts['all'],
                className: selectedChannel ? '' : 'active',
                onClick: () => setSelectedChannel(null),
              },
              ...channelsList.map(({ id, title, name }) => ({
                className: selectedChannel?.id === id ? 'active' : '',
                title,
                count: counts[name || id],
                onClick: () => setSelectedChannel({ id, title }),
              })),
            ]}
          />
        )
      }
      <NotificationSettingsModal />
    </>
  )
}


export default React.memo(Notifications)
