import { useMemo } from 'react'
import { useQuery } from 'hooks'


const useChannelsList = () => {
  const queryOptions = useMemo(() => ({
    url: '/ajax.php?action=chatChannelSearch&term',
    modifyResult: (data) => {
      return data?.filter(({ deleted }) => !deleted) || []
    },
  }), [])

  const { data, isFetching } = useQuery<{ label: string, subscribed: number, emailSubscribed: number, rights: number, value: number }[]>(queryOptions)

  return {
    channelsList: data || [],
    isChannelsListFetching: isFetching,
  }
}


export default useChannelsList
