import React, { useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import equal from 'fast-deep-equal'
import { modalVisibility, ModalVisibilityProps, openModal } from 'modal'

import { useChannelsList, useSubscribe } from 'models/notifications'
import { useUser } from 'models/user'

import { openConfirmModal } from 'compositions/modals'

import { Modal } from 'components/feedback'
import { Announcement, CheckboxIcon, Text } from 'components/dataDisplay'
import { Button, ButtonBase } from 'components/inputs'

import s from './NotificationSettingsModal.scss'


const NotificationSettingsModal: React.FC<ModalVisibilityProps> = (props) => {
  const { closeModal } = props

  const [ selectedChannels, setSelectedChannels ] = useState([])
  const [ selectedChannelsForEmail, setSelectedChannelsForEmail ] = useState([])

  const { subscribeChannels, subscribeChannelsForEmail } = useSubscribe()
  const { channelsList, isChannelsListFetching } = useChannelsList()

  const initialSelectedChannels = useMemo(() => {
    return channelsList
      .filter(({ value, subscribed }) => value < 300 && subscribed)
      .map(({ value }) => value)
  }, [ channelsList ])

  const initialSelectedEmailChannels = useMemo(() => {
    return channelsList
      .filter(({ value, emailSubscribed }) => value < 300 && emailSubscribed)
      .map(({ value }) => value)
  }, [ channelsList ])

  const initialSelectedChannelsRef = useRef(initialSelectedChannels)
  initialSelectedChannelsRef.current = initialSelectedChannels

  const initialSelectedEmailChannelsRef = useRef(initialSelectedEmailChannels)
  initialSelectedEmailChannelsRef.current = initialSelectedEmailChannels

  useEffect(() => {
    if (!isChannelsListFetching) {
      setSelectedChannels(initialSelectedChannelsRef.current)
      setSelectedChannelsForEmail(initialSelectedEmailChannelsRef.current)
    }
  }, [ isChannelsListFetching ])

  const isChanged = !equal(selectedChannels, initialSelectedChannels)
  const isEmailChanged = !equal(selectedChannelsForEmail, initialSelectedEmailChannels)

  const { hasAuth } = useUser()

  return (
    <Modal
      className="w-full"
      title="Настройка уведомлений"
      width={618}
      closeModal={closeModal}
    >
      <div className="w-full">
        {
          !hasAuth &&
            <Announcement
              title="Зарегистрируйтесь, чтобы получать уведомления на имейл o том, что вам интересно"
            />
        }
        <div className={cx('grid w-full mr-16px mb-8px', s.grid)}>
          <Text
            className="w-full opacity-72"
            size="t16-20"
            message="Раздел сайта"
          />
          <Text
            className="opacity-72 mr-16px"
            size="t16-20"
            message="На сайте"
          />
          <Text
            className={cx(!hasAuth? 'opacity-48' : 'opacity-72')}
            size="t16-20"
            message="На имейл"
          />
        </div>
        {
          channelsList
            .filter(({ value }) => value < 300)
            .map(({ label, value }, index) => {
              const isActive = selectedChannels.includes(value)
              const isMailActive = selectedChannelsForEmail.includes(value)

              return (
                <div className={cx('grid w-full', s.border)}>
                  <Text
                    className={cx('py-16px w-full text-left', {
                      [s.border]: index,
                    })}
                    message={label}
                    size="t16-20"
                    color="titanic"
                  />
                  <ButtonBase
                    className='px-4px mr-16px'
                    key={index}
                    onClick={() => {
                      if (isActive) {
                        setSelectedChannels(
                          selectedChannels.filter((id) => id !== value)
                        )
                      }
                      else {
                        setSelectedChannels(
                          selectedChannels.concat(value)
                        )
                      }
                    }}
                  >
                    <CheckboxIcon
                      active={isActive}
                    />
                  </ButtonBase>
                  <ButtonBase
                    className='px-4px'
                    key={index}
                    disabled={!hasAuth}
                    onClick={() => {
                      if (isMailActive) {
                        setSelectedChannelsForEmail(
                          selectedChannelsForEmail.filter((id) => id !== value)
                        )
                      }
                      else {
                        setSelectedChannelsForEmail(
                          selectedChannelsForEmail.concat(value)
                        )
                      }
                    }}
                  >
                    <CheckboxIcon
                      active={isMailActive}
                    />
                  </ButtonBase>
                </div>

              )
            })
        }
      </div>
      <div style={{ height: '240px' }} />
      <div className="fixed shadow-titanic-1 w-full bottom-0 left-0 z-800 bg-white">
        <div className="pt-40px pb-32px mw-618 mx-auto">
          <Button
            className="mt-24px"
            title="Сохранить"
            style="primary"
            disabled={!isChanged && !isEmailChanged}
            size={40}
            fullWidth
            onClick={() => {
              setTimeout(closeModal, 500)

              openConfirmModal({
                title: `Сохранить изменения?`,
                text: 'Вы внесли изменения в настройку уведомлений',
                cancelButtonTitle: 'Не сохранять',
                confirmButtonTitle: 'Да, сохранить',
                onConfirm: () => {
                  const channelsToRemove = initialSelectedChannels
                    .filter((channelId) => (
                      !selectedChannels.includes(channelId)
                    ))
                    .map((channelId) => ({
                      channel: channelId,
                      subscribe: false,
                    }))

                  const channelsEmailToRemove = initialSelectedEmailChannels
                    .filter((channelId) => (
                      !selectedChannelsForEmail.includes(channelId)
                    ))
                    .map((channelId) => ({
                      channel: channelId,
                      subscribe: false,
                    }))

                  const channelsToAdd = selectedChannels
                    .filter((channelId) => (
                      !initialSelectedChannels.includes(channelId)
                    ))
                    .map((channelId) => ({
                      channel: channelId,
                      subscribe: true,
                    }))

                  const channelsEmailToAdd = selectedChannelsForEmail
                    .filter((channelId) => (
                      !initialSelectedEmailChannels.includes(channelId)
                    ))
                    .map((channelId) => ({
                      channel: channelId,
                      subscribe: true,
                    }))

                  subscribeChannels([
                    ...channelsToAdd,
                    ...channelsToRemove,
                  ])

                  subscribeChannelsForEmail([
                    ...channelsEmailToAdd,
                    ...channelsEmailToRemove,
                  ])
                },
              })
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

export const openNotificationSettingsModal = () => openModal('NotificationSettingsModal')


export default modalVisibility('NotificationSettingsModal', NotificationSettingsModal)
