const getUserName = ({ firstName, middleName, lastName, email }) => (
  [ lastName, firstName, middleName ].filter(Boolean).join(' ') || email
)

const getOrgAndPositionText = ({ organisations, orgPosition }) => {
  if (organisations || orgPosition) {
    const orgText = organisations ? organisations : 'Организация не задана'
    const positionText = orgPosition ? orgPosition : 'Должность не задана'

    return `${orgText}, ${positionText}`
  }

  return 'Организация и должность не заданы'
}

const modifyUser = (user) => {
  if (user) {
    const userName = getUserName(user)
    const orgAndPositionText = getOrgAndPositionText(user)

    return {
      ...user,
      accesses: user.accesses || [],
      userName,
      orgAndPositionText,
    }
  }

  return null
}


export default modifyUser
