import { LicenseApiData } from 'models/city'


const getModuleTitle = (modules: LicenseApiData, tag: UserModel.ModuleTag) => {
  const module = modules?.find(({ tag: moduleTag }) => tag === moduleTag)
  const { title, customTitle } = module || {}

  return customTitle || title
}

export default getModuleTitle