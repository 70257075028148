import { useMemo } from 'react'
import { useQuery } from 'hooks'


const useCategories = () => {
  const queryOptions = useMemo(() => ({
    url: '/ajax.php?action=getAllProblemTypes&asArray=1',
    modifyResult: ({ all }: { all: AdminAppealsHomePage.CategoriesApiData[] }): AdminAppealsHomePage.Category[] => (
      all
        .filter(({ hidden, disabled }) => !Number(hidden) && !Number(disabled))
        .map(({ typeFull, typeIdx, icon }, index) => ({
          value: typeIdx,
          title: typeFull,
          icon,
        }))
        .sort((a, b) => a.title.localeCompare(b.title))
    ),
  }), [])

  const { data, isFetching } = useQuery<AdminAppealsHomePage.Category[]>(queryOptions)

  const categoryTitleById = useMemo(() => {
    if (data) {
      return data.reduce((result, { title, value }) => {
        result[value] = title

        return result
      }, {})
    }

    return null
  }, [ data ])

  return {
    categories: data || [],
    categoryTitleById,
    isCategoriesFetching: isFetching,
  }
}


export default useCategories
