import { useCallback } from 'react'
import { useMutation } from 'hooks'


const useSubscribe = () => {
  const [ submit, { isSubmitting } ] = useMutation({
    url: '/ajax.php?action=saveEventsActiveOptions', // setForKey=Int&chat=1|0
  })

  const subscribeChannel = useCallback(({ channel, subscribe }) => {
    return submit({
      url: `/ajax.php?action=saveEventsActiveOptions&setForKey=${channel}&chat=${subscribe ? 1 : 0}`,
    })
  }, [ submit ])

  const subscribeChannelForEmail = useCallback(({ channel, subscribe }) => {
    return submit({
      url: `/ajax.php?action=saveEventsActiveOptions&setForKey=${channel}&email=${subscribe ? 1 : 0}`,
    })
  }, [ submit ])

  const subscribeChannels = useCallback(async (channels) => {
    const results = await Promise.all(
      channels.map(({ channel, subscribe }) => subscribeChannel({ channel, subscribe }))
    )

    const { data, cacheStorage } = results[results.length - 1]

    cacheStorage.reset('/ajax.php?action=chatChannelSearch&term')
  }, [ subscribeChannel ])

  const subscribeChannelsForEmail = useCallback(async (channels) => {
    const results = await Promise.all(
      channels.map(({ channel, subscribe }) => subscribeChannelForEmail({ channel, subscribe }))
    )

    const { data, cacheStorage } = results[results.length - 1]

    cacheStorage.reset('/ajax.php?action=chatChannelSearch&term')
  }, [ subscribeChannelForEmail ])

  return {
    isSubmitting,
    subscribeChannel,
    subscribeChannels,
    subscribeChannelForEmail,
    subscribeChannelsForEmail,
  }
}


export default useSubscribe
