import { SocialIconName } from 'components/dataDisplay'


export const socials: { [cityName: string]: SocialIconName[] } = {
  'город Тула': [
    '40/Gosuslugi_40',
  ],
  'Пуровский район': [
    '40/Gosuslugi_40',
    '40/Google_40',
    '40/Apple_40',
    '40/Mailru_40',
    '40/Yandex_40',
    '40/Vkontakte_40',
    '40/Odnoklassniki_40',
  ],
  'Мичуринск': [
    '40/Mailru_40',
    '40/Yandex_40',
    '40/Vkontakte_40',
    '40/Odnoklassniki_40',
  ],
  'Сочи': [
    '40/Gosuslugi_40',
    '40/Google_40',
    '40/Mailru_40',
    '40/Yandex_40',
    '40/Vkontakte_40',
    '40/Odnoklassniki_40',
  ],
  'Курчатов': [
    '40/Gosuslugi_40',
  ],
}

export const defaultSocials: SocialIconName[] = [
  '40/Gosuslugi_40',
  '40/Google_40',
  '40/Apple_40',
  '40/Mailru_40',
  '40/Yandex_40',
  '40/Vkontakte_40',
  '40/Odnoklassniki_40',
]
