/* submit wrapper for Java backend
checks for errors and opens corresponding modals */

import links from 'links'

import { openSubmitFailedModal, openSubmitSucceededModal } from 'compositions/modals'


export type JavaResponse = {
  error?: string
  description?: string
  created?: boolean
  updated?: boolean
  deleted?: boolean
  status?: number
  operation?: {
    updated?: boolean
  }
  success?: number
}

type Input = {
  job: () => Promise<{ data: JavaResponse, cacheStorage, errors? }>
  isOpenSubmitSucceeded?: boolean
  failedTitle?: string
  succeededTitle?: string
  succeededRedirectLink?: string
  failedButtonTitle?: string
  failedRedirectLink?: string
  succeededCallback?: ({ data, cacheStorage, errors }) => void
  failedCallback?: () => void
}

const submitAndCheckForErrors = (props: Input) => {
  const {
    job, isOpenSubmitSucceeded = true, failedTitle, succeededTitle, failedButtonTitle, failedRedirectLink,
    succeededRedirectLink, succeededCallback } = props

  return job()
    .then(({ data, cacheStorage, errors }) => {
      const { error, created, updated, deleted, status, operation, success, description } = data || {}
      const { updated: operationUpdated } = operation || {}

      if (errors) {
        openSubmitFailedModal({
          title: 'Сетевая ошибка:',
          subTitle: errors,
          text: 'Повторите запрос позже',
        })

        return Promise.reject(errors)
      }
      else if (error
        || created === false
        || updated === false
        || deleted === false
        || operationUpdated === false
        || status < 0
        || success === 0) {
        openSubmitFailedModal({
          title: failedTitle || 'Ошибка внесения изменений',
          subTitle: error || description || '',
          buttonTitle: failedButtonTitle,
          redirectLink: failedRedirectLink,
        })

        return Promise.reject(error)
      }
      else if (isOpenSubmitSucceeded) {
        openSubmitSucceededModal({
          title: succeededTitle || 'Изменения успешно внесены',
          redirectLink: succeededRedirectLink ? succeededRedirectLink : undefined,
          callback: succeededCallback
            ? () => succeededCallback({ data, cacheStorage, errors })
            : undefined,
        })
      }

      return Promise.resolve(data)
    })
}

export default submitAndCheckForErrors