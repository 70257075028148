import React from 'react'
import config from 'config'
import { useDevice } from 'device'

import { useUser } from 'models/user'
import { getImage } from 'models/mapLayer'

import { Avatar } from 'components/dataDisplay'
import { ActionButton } from 'components/inputs'


type ProfileButtonProps = {
  isOpen?: boolean
}

const ProfileButton: React.FC<ProfileButtonProps> = ({ isOpen }) => {
  const { user, hasAuth } = useUser()
  const { isMobile } = useDevice()

  const { login, pic, image } = user || {}

  return (
    <div className="flex ml-24px">
      {
        hasAuth ? (
          <>
            <Avatar
              image={
                config.isLegacyAuth
                  ? (pic ? getImage(pic) : null)
                  : (image ? getImage(image) : null)
              }
              size={24}
              userName={login}
            />
            {
              !isMobile && (
                <ActionButton
                  className="ml-4px"
                  title="Профиль"
                  color="titanic"
                  arrow={isOpen ? 'up' : 'down'}
                />
              )
            }
          </>
        ) : (
          <ActionButton
            title={isMobile ? null : 'Профиль'}
            icon="main/avatar_16"
            color="titanic"
            arrow={isMobile ? null : (isOpen ? 'up' : 'down')}
          />
        )
      }
    </div>
  )
}


export default ProfileButton
