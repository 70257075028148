import { useMemo } from 'react'
import { useQuery } from 'hooks'


const useCityConfig = (skip?: boolean) => {
  const queryCity = useMemo(() => ({
    url: '/nginxApi.php?get=city',
    modifyResult: (data) => ({
      ...data,
      iosLink: data.mobileAppstore,
      androidLink: data.mobileGoogleplay,
    }),
    skip,
  }), [ skip ])

  const { data: cityData, isFetching: isCityDataFetching } = useQuery<City.PhpCityData>(queryCity)

  return {
    cityConfig: cityData || {} as City.PhpCityData,
    isCityConfigFetching: isCityDataFetching,
  }
}


export default useCityConfig
