import React from 'react'
import cx from 'classnames'
import links from 'links'
import config from 'config'
import { useDevice } from 'device'
import { useUser } from 'models/user'

import { openSearchModal } from 'compositions/modals'
import Notifications from 'compositions/admin/mapLayers/AdminHeader/Notifications/Notifications'

import { Logo } from 'components/dataDisplay'
import { ActionButton } from 'components/inputs'
import { WidthContainer } from 'components/layout'
import { DropdownMenu, Href } from 'components/navigation'

import ProfileButton from '../ProfileButton/ProfileButton'

import BurgerMenu from './components/BurgerMenu/BurgerMenu'

import s from './Mobile.scss'


const Mobile = ({ items, withHeaderMenu }) => {
  const { hasAuth } = useUser()
  const { isMobile } = useDevice()

  return (
    <header>
      <WidthContainer className={cx(s.header, 'flex items-center', isMobile ? 'py-8px' : 'py-20px')}>
        {
          withHeaderMenu && (
            <BurgerMenu />
          )
        }
        <Href className="flex" to={links.home}>
          <Logo
            className={cx(s.logo, { [isMobile ? 'ml-16px' : 'ml-24px']: withHeaderMenu })}
          />
        </Href>
        <div className="flex-1" />
        {
          withHeaderMenu && (
            <ActionButton
              id="search"
              className="mr-4px"
              icon="main/search_16"
              color="titanic"
              onClick={openSearchModal}
            />
          )
        }
        {
          (hasAuth && config.env === 'test') && (
            <Notifications
              className="ml-24px"
            />
          )
        }
        <DropdownMenu items={items} placement="bottomRight" style="blank">
          <ProfileButton />
        </DropdownMenu>
      </WidthContainer>
    </header>
  )
}


export default Mobile
